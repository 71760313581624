.partitioned {
    padding-left: 18px;
    letter-spacing: 35px;
    border: 0;
    background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 50px 1px;
    background-repeat: repeat-x;
    background-position-x: 35px;
    width: 300px;
    outline : none;
  }
  .divOuter{
    width: 300px; 
    overflow: hidden;
  }
  .divInner{
    left: 0;
    position: sticky;
  }
  