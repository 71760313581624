@media (max-width: 991.98px) {
  .offcanvas-collapse {
    position: fixed;
    top: 56px;
    /* Height of navbar */
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: #f8f9fa;
    transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }

  .offcanvas-collapse.open {
    visibility: visible;
    transform: translateX(-100%);
  }
}

.bg-custom-light {
  background-color: #e7e7e7;
}

.bg-dim {
  background-color: rgba(0, 0, 0, 0.6);
}

.cover {
  object-fit: cover;
}

.opacity-6 {
  opacity: 0.6;
}

.opacity-5 {
  opacity: 0.5;
}

.image-vertical-scroller {
  overflow-y: auto;
  max-height: 460px;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}

.image-vertical-scroller::-webkit-scrollbar {
  display: none;
}

.h-scroller {
  position: relative;
  z-index: 2;
  height: 3.75rem;
  overflow-y: hidden;
}

.h-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  color: rgba(255, 255, 255, .75);
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.h-underline .h-link {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: .875rem;
  color: #6c757d;
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  background-color: #f3f4f6;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Roboto, Segoe UI,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
}

#__next {
  height: 100%;
}

.btn-facebook {
  background: #3b5998;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border: 0;
  border-radius: 0.25rem;
  color: white;
  font-weight: 400;
  width: 40px;
  height: 40px;
}

.btn-google {
  background: #db4a39;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border: 0;
  border-radius: 0.25rem;
  color: white;
  font-weight: 400;
  width: 40px;
  height: 40px;
}

.btn-apple {
  background: #000000;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border: 0;
  border-radius: 0.25rem;
  color: white;
  font-weight: 400;
  width: 40px;
  height: 40px;
}

.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}

.bg-white {
  background: white;
}

.bg-background {
  background: #f3f4f6;
}

.bg-primary-darker {
  background: #1448cc;
}

.footer-link {
  color: rgba(255, 255, 255, 0.75);
  text-decoration: none;
}

.footer-link:hover {
  color: white;
  text-decoration: none;
}

.dropdown-menu-macos {
  gap: 0.25rem;
  padding: 0.5rem;
}

.dropdown-menu-macos .dropdown-item {
  border-radius: 0.25rem;
}

.dropdown-menu-macos .dropdown-item:hover {
  border-radius: 0.25rem;
  color: #fff;
  background-color: var(--bs-primary);
}

.dropdown-item-danger {
  color: var(--bs-red);
}

.dropdown-item-danger:hover,
.dropdown-item-danger:focus {
  color: #fff;
  background-color: var(--bs-red);
}

.dropdown-item-danger.active {
  background-color: var(--bs-red);
}

.my-list-item {
  color: #000000;
  text-decoration: none;
  font-weight: 500;
  border-radius: 0.25rem;
}

.my-list-item svg {
  color: #757575;
}

.my-list-item:hover {
  border-radius: 0.25rem;
  background-color: #f2f2f2;
  font-weight: 500;
  color: black;
}

.my-list-item.active {
  color: var(--bs-primary);
  background-color: var(--bs-secondary);
  text-decoration: none;
  font-weight: 500;
  border-radius: 0.25rem;
}

.my-list-item.active svg {
  color: var(--bs-primary);
}

.scroll-container {
  padding: 16px;
}

@keyframes scrollLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}