
  
  .dropdown.open .dropdown-content {
    display: block;
    opacity: 1;
    transform: translateY(0);
  }
  
  #languageSelect {
    width: 100%;
  }
  
  #languageSelect:focus {
    outline: none;
  }
  