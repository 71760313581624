$primary:#f75f01;
// $primary:#f76f01;
$info:#f57f11e0;





//$secondary:     #0d6efd;
//$border-color: rgba(255,255,255,.15);

// $navbar-light-color: rgba(0,0,0, .4);

@import "../node_modules/bootstrap/scss/bootstrap";


